import { ref } from "vue";
export default {
  name: "Fee",
  setup() {
    const tableData = ref([]);
    tableData.value = [{
      astNo: 1,
      astName: '性格、爱情、事业、财运、健康、婚姻、子女等本命星盘项目',
      astByItem: '600元/项目',
      astByTime: '1200元/小时'
    }, {
      astNo: 2,
      astName: '生时校正、择地占星、两人关系，爱情合盘',
      astByItem: '1200元/项目',
      astByTime: '1200元/小时'
    }, {
      astNo: 3,
      astName: '卜卦',
      astByItem: '300元/项目',
      astByTime: ''
    }, {
      astNo: 4,
      astName: '择日、公司开业',
      astByItem: '1200元/项目',
      astByTime: ''
    }, {
      astNo: 5,
      astName: '本命全盘',
      astByItem: '3600元/项目',
      astByTime: ''
    }, {
      astNo: 6,
      astName: '生时校正',
      astByItem: '1200元/项目',
      astByTime: ''
    }];
    const tableTaskData = ref([]);
    tableTaskData.value = [{
      astItem: "本命全盘",
      astContent: "本案例分析包括但不限于：\n个人性格\n适合什么样的职业\n拥有怎样的财运\n婚姻如何\n" + "父母以及个人或者伴侣的健康如何\n子女情况等等。\n除以上之外高级包括但不限于：\n1." + "使用更多检查方法，提供更多细节。\n2.免费校正（误差2小时内）。",
      astPrice: "3600元"
    }, {
      astItem: "性格",
      astContent: "性格决定命运 （播下一种行动，你将收获一种习惯；播下一种习惯，你将收获一种性格；播下一种性格，你将收获一种命运。我将会从性格的四个维度的特征，态度特征、意志特征、理智特征与情绪特征来对您的性格做全面分析。",
      astPrice: "600元"
    }, {
      astItem: "职业",
      astContent: "1、您的天赋所在。 （1）性格特点： （2）工作的态度： （3）工作能力： 2.适合的工作方向。 3.大致工作运势的走向。（十年为单位，周期内的情况） 4.建议",
      astPrice: "600元"
    }, {
      astItem: "工作",
      astContent: "1.适合的工作类型推荐。 2.工作中容易出现哪些情况。 3.在受雇于人的业绩。",
      astPrice: "600元"
    }, {
      astItem: "财运",
      astContent: "主要告诉您您将拥有怎样的财运， 1.您处理钱财的态度； 2.你的的财运是否顺畅； 3.赚钱的容易与否； 4.赚钱机会的多寡； 5.财务上是否会有突发状况及注意的时间； 6.您大概在什么财富等级",
      astPrice: "600元"
    }, {
      astItem: "爱情",
      astContent: "本案例分析包括但不限于： 1.本人对待爱情的态度。 2.桃花运的多寡。 3.你喜欢什么样的爱人？ 4.爱情是否会顺利。在爱情中容易遇到哪些问题？ 5.如何跳出爱情陷阱，拥有和谐、幸福的爱情？",
      astPrice: "600元"
    }, {
      astItem: "婚姻",
      astContent: "本案例分析包括但不限于：结婚时间的预测；什么时候遇见、如何遇见的；对方的基本情况，长相，家境，性格，事业；婚姻是否会幸福；需要注意的事项",
      astPrice: "600元"
    }, {
      astItem: "健康",
      astContent: "本案例分析包括但不限于：幸福的首要条件在于健康。现在的人们生活节奏越来越快，压力越来越大，健康的重要性也越来越得到人们的重视，这里将运用占星理论分析探讨你的健康以及养生之道。身体健康是我们人生的财富，有了健康的身体，才能更好的生活，测算内容包括，您可能出现的健康问题及时间；适合您的健康饮食；适合您的运动；无论是好还是坏，我们每个人也都应该对自己的健康负责，有了好身体才能更好的去生活，去工作，去恋爱，去做更多有意义的事情！",
      astPrice: "600元"
    }, {
      astItem: "子女",
      astContent: "本案例分析包括但不限于： 1.是否容易怀孕，及可能的子女数量 2.子女基本情况，事业，钱财，健康，相貌特点等 3.和子女相处的情况 4.怀孕时间的预测准确到年份",
      astPrice: "600元"
    }, {
      astItem: "两人关系",
      astContent: "用于两个人的互动，使用占星合盘的方法，不仅仅是看情侣、夫妻，也可以看子女与自己，事业合作伙伴等。合盘不能告诉你，但可以建议，这个人是不是你未来的真命天子/女。（是不是确定是需要通过合盘推运，价格见推运） 本案例分析包括但不限于： 1.是否吸引：两个人的激情吸引情况；情绪上、灵魂上的吸引情况； 2.是否能够建立感情：爱情关系中能够引起的温暖体验； 3.是否能够长久相处：维持关系的决定期许或担保。",
      astPrice: "1200元"
    }, {
      astItem: "年运预测",
      astContent: "主要告诉你，什么时候事业会有转机，什么时候财运会好，什么时候结婚，什么时候会有子女运等等，流年盘重点在于“何时会发生的事件”。 按照事件内容和时间段（年、月）为计费单位，年运的起止点是从生日到生日。职业，财运，婚姻，健康，子女等。一个单独周期内的情况，须先选定时间范围，一年或者一个月。 " + "按照观察星盘数量收费，例如：预测2年内整体情况基本费用为600*2=600元。如果事情周期短，可以按月计算，价格相同。",
      astPrice: "1200元"
    }, {
      astItem: "出生时间矫正",
      astContent: "出生时间不够准确，或者不知道准确的时间，可以选择校正生时。" + "一般出生时间误差，比如你出生时间确定在17：00-17：30之间，就属于误差半小时。半小时之内收费300元，2小时内收费600元，超出双方协商。",
      astPrice: "600元"
    }, {
      astItem: "选择时间",
      astContent: "用于挑选开业、企业项目上线、媒体网站上线、婚姻、出国、出行、申请报告、搬迁、入宅、剖腹生产等时间定夺选取。。",
      astPrice: "600元"
    }, {
      astItem: "卜卦、决策问题",
      astContent: "用于问具体事件，三个月内有结果的，利用卜卦的方法，一事一卦，如是否会有复合的机会，" + "是否能够拿到事业的合作或者签约机会，是否可以出国，是否可以办理某个手续顺利完成等等。",
      astPrice: "300元"
    }, {
      astItem: "择地、选择地点",
      astContent: "用于适合自己移民、留学、搬迁、发展等等的方向、地区、国家挑选。运用A*C*G星盘看你的幸运地：" + "择地占星改变你的命运。控制变量法，改变了出生的地点，会带来很多星体能量的放大，可以善用吉星的力量，避免凶星的力量来达到改运的效果。",
      astPrice: "1200元"
    }];
    const tableDiscountData = ref([]);
    tableDiscountData.value = [{
      discountContent: "1600元以上",
      discountAmount: "85折"
    }, {
      discountContent: "3200元以上",
      discountAmount: "80折"
    }];
    return {
      tableData,
      tableTaskData,
      tableDiscountData
    };
  }
};